import CitizenReportsMap from '@/components/CitizenReportsMap.vue';
import { defineComponent, reactive } from '@vue/composition-api';
import axios from '@/plugins/axios';
import { useToast } from 'vue-toastification/composition';
import CitizenReportImage from '@/views/user/citizen-reports/CitizenReportImage.vue';
import { citizenReportStatusColors, citizenReportStatusLabels } from '@/types';
const headers = [
    {
        text: 'Data',
        value: 'createdAtFmt',
        sortable: false
    },
    {
        text: 'Status',
        value: 'status',
        sortable: false
    },
    {
        text: 'Localitatea',
        value: 'localityName',
        sortable: false
    },
    {
        text: 'Judetul',
        value: 'countyName',
        sortable: false
    },
    {
        text: 'Categoria',
        value: 'categoryName',
        sortable: false
    },
    {
        text: 'Descrierea',
        value: 'shortDescription',
        sortable: false
    },
    {
        text: '',
        value: 'data-table-expand'
    }
];
export default defineComponent({
    components: {
        CitizenReportImage,
        CitizenReportsMap
    },
    setup() {
        const toast = useToast();
        const state = reactive({
            citizenReports: {
                loading: false,
                items: [],
                error: null
            }
        });
        const icons = {
            resolved: {
                name: 'mdi-check',
                color: 'success'
            },
            rejected: {
                name: 'mdi-close',
                color: 'error'
            },
            new: {
                name: 'mdi-help',
                color: 'primary'
            }
        };
        const loadCitizenReports = () => {
            state.citizenReports.loading = true;
            return axios.get('my-citizen-reports')
                .then((response) => {
                state.citizenReports.items = response.data.data.docs.map((citizenReport) => ({
                    ...citizenReport,
                    createdAtFmt: new Intl.DateTimeFormat('ro', { dateStyle: 'short', timeStyle: 'short' })
                        .format(new Date(citizenReport.createdAt)),
                    icon: icons[citizenReport.status.toLowerCase()],
                    shortDescription: citizenReport.description.length > 50
                        ? citizenReport.description.slice(0, 50) + '…'
                        : citizenReport.description,
                    status: {
                        value: citizenReport.status,
                        label: citizenReportStatusLabels[citizenReport.status] || 'Necunoscut',
                        color: citizenReportStatusColors[citizenReport.status] || 'blue'
                    }
                }));
            })
                .catch((err) => {
                toast.error(err.message);
            })
                .finally(() => {
                state.citizenReports.loading = false;
            });
        };
        loadCitizenReports();
        return {
            state,
            headers
        };
    }
});
